import React from "react";

const ContactForm = () => {
  return (
    <form
      className="w-full max-w-[430px] md:max-w-[unset] bg-primary-blue"
      name="contact-form"
      data-netlify="true"
      data-form="contact-form"
      method="POST"
      netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact-form" />

      <input type="radio" name="type" value="general" />
      <input type="radio" name="type" value="membership" />
      <input type="radio" name="type" value="event" />

      <div className="flex flex-col mt-xl gap-xs">
        <input placeholder="First Name" name="first_name" required />
        <input placeholder="Last Name" name="last_name" required />
        <input placeholder="Email" name="email" type="email" required />
        <div className="flex items-center gap-xs">
          <input placeholder="+" className="w-[64px]" name="prefix-phone" type="tel" />
          <input placeholder="Telephone" className="flex-1" type="tel" name="phone-number" />
        </div>
        <input placeholder="Company" name="company" />
        <textarea placeholder="Message" name="message" />
        {/*<input type="checkbox" name="check" />*/}
        <button className="bg-white" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};
export default ContactForm;
